import React, { Component } from 'react';


import { Card } from "../components/Card"


export function Contact() {

    function handleSubmit(e) {
        e.preventDefault();
        let name = e.target.name.value;
        let contact = e.target.phone.value;
        let message = e.target.message.value;


        if (name.toString().length === 0) {
            document.getElementById('mail_status').style.color = "red";
            document.getElementById('mail_status').innerHTML = "Enter Valid Name.";
            document.getElementById('name').focus();
            return;
        }
        if (contact.toString().length === 0) {
            document.getElementById('mail_status').style.color = "red";
            document.getElementById('mail_status').innerHTML = "Enter Valid Contact No.";
            document.getElementById('phone').focus();
            return;
        }
        if (message.toString().length === 0) {
            document.getElementById('mail_status').innerHTML = "Enter Valid Message.";
            document.getElementById('mail_status').style.color = "red";
            document.getElementById('message').focus();
            return;
        }
        let obj = { name, contact, message };


        const requestOptions = {
            method: 'POST',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(obj)
        };
        document.getElementById('mail_status').innerHTML = "Please Wait....";
        document.getElementById('mail_status').style.color = "black";

        const URL = 'https://script.google.com/macros/s/AKfycbwkJkbDIzXIwv07gIrfd4o9L322h055r3KpvSE4jH5Xp8ZaKD99UMEF0Xaurv-PWuBr/exec'
        fetch(URL, requestOptions)
            .then(response => {
                document.getElementById('mail_status').innerHTML = "Message Was Sent Successfully.<br>We will Contact You shortly.";
                document.getElementById('mail_status').style.color = "green";

                e.target.name.value = "";
                e.target.phone.value = "";
                e.target.message.value = "";
                return response;
            })
            .catch(err => {
                document.getElementById('mail_status').innerHTML = "Error Occored. Please Try Again.";
                document.getElementById('mail_status').style.color = "red";
                console.log(err)
            });

    }



    return (<>
        <Card 
            title="Rastriya Saving And Credit Cooperative Ltd."
            location="Bharatput-03, LiosChowk"
            description="056-598163,598164"
            email="rastriyasaccos@gmail.com"
            width='100%'
        />

        <div className="contatc_form_container" lang="en">
            <h2>
                Contact/Message Us.
            </h2>
            <hr /><br />



            <form onSubmit={handleSubmit} lang="en">
                <table>
                    <tbody>
                        <tr>
                            <td><label for="name">Name:</label></td><td><input placeholder="Enter Your Full Name." id="name" /></td>
                        </tr>
                        <tr>
                            <td><label for="phone">Phone Number:</label></td><td><input placeholder="Enter Your Contact No." id="phone" /></td>
                        </tr>
                    </tbody>
                </table>

                <label>Message:<textarea style={{ 'width': '100%', 'height': '150px' }} placeholder="Write Your MessageHere." id="message" /></label>

                <button type="submit">Send Message</button>
                <br></br>
                <p id="mail_status" style={{ "font-weight": "bold" }}></p>
            </form>


        </div>

        <h2>Location Map</h2>
        <iframe title="GOOGLE_MAPS_LOCATION" 
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3532.5941014992604!2d84.42387817501495!3d27.698937476187456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s7MV6MCXG%2BHHG!5e0!3m2!1sen!2snp!4v1693561550905!5m2!1sen!2snp"
        style={{"border":"0","width":"100%"}} height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
   </>)
}
