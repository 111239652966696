import React, { Component } from 'react';

import './Card.css';

export function Card(props) {
    return (
        <div className="styleCardContent card__container" style={{ 'width': props.width }}>
            <h3 className="styleCardTitle">{props.title}</h3>
            <hr />
            <p className="styleLocationLabel">{props.location}</p>
            <p className="styleDescription">{props.description}</p>
            <p className="styleEmail">{props.email}</p>
        </div>
    );
}

