import { Card } from "../components/Card"
import React, { Component } from 'react';


export function Services() {
    return (<>
        <div lang="en">
            <Card
                title="Deposit"
                location="Save for Future."
                description="Deposits allow individuals to set aside money for future expenses or emergencies. This builds a cushion of savings that can help them weather unexpected financial challenges and avoid debt"

            />
            <Card
                title="Loan & Credits."
                location=""
                description=" For entrepreneurs and small business owners, emergencies can arise that require immediate funding, such as equipment breakdowns, unexpected expenses, or loss of inventory. Loans can provide quick access to capital to help keep the business running."

            />
        </div>
    </>)
}