import React, { Component } from 'react';
import { Link, Route, Routes } from "react-router-dom";

import './BOD.css';


export function BOD(props) {
    return (
        <div className="bod_element" style={{ 'width': props.width }} >
            <div className='bod_img'>
                <img src={props.src} alt={props.alt}></img>
            </div>


            <p className="styleCardTitle" style={{ "font-size": "1.25rem" }}><strong>{props.name}</strong></p>
            <p className="styleCardTitle"><strong>{props.title}</strong></p>
            <Link to={`tel:${props.phone}`} className="styleCardTitle">{props.phone}</Link>

        </div >
    );
}

