import React, { Component } from 'react';
import { Link } from "react-router-dom"

import "./BankDetailBANK.css"



export function BankDetailBANK(props) {

    return (

        <>
            <h1></h1>
            <div className="Bank_detail">
                <h2>{props.BankName}</h2>

                <h4> <pre>Account Name   </pre> : {props.AccountName}</h4>
                <h4> <pre>Account Number </pre> : {props.AccountNumber}</h4>
                <h4> <pre>Branch         </pre> : {props.Branch}</h4>
            </div>


        </>
    )
}