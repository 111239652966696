import React, { Component } from 'react';

import { BOD } from "../components/BOD";



export function About() {

    return (<>
        <div style={{ "font-size": "1.25rem" }} lang="ne">

            स्थानीय प्रतिष्ठित, व्यवसायिक, समाजसेवी व्यक्तिहरूको समूहद्वारा सहकारी ऐन २०४८ अन्तर्गत वि.सं. २०५५/६/२१ मा
            स्थापना भै चितवन जिल्लाभर कार्यक्षेत्र रहेको यस राष्ट्रिय बचत तथा ऋण सहकारी संस्था लि. ले सदस्य केन्द्रित भई
            आफ्ना सदस्यहरूको आर्थिक, सामाजिक तथा सांस्कृतिक समृद्धी हासिल गर्नु प्रमुख उद्देश्य रहेको छ । स्वावलम्बन,
            स्व-उत्तरदायित्व, सामाजिक उत्तरदायित्व यस संस्थाको मूल्य एवं मान्यता रहेको छ ।
            <br />
            <br />
            हामी विभिन्न प्रकारका वित्तीय सेवाहरू (बचत, ऋण र रेमिट्यान्स) प्रदान गर्दछौं । सदस्य तथा समुदायको हितलाई
            प्राथमिकतामा राखी समाजको विकासमा सकारात्मक प्रभाव पार्ने अत्याधुनिक सेवाहरुको अध्ययन गरी समयसापेक्ष सुविधा
            प्रदान गर्दै आएको छौँ ।


        </div>
        <br />

        <div className="bod_member_container" lang="en">
            <h2 className='sticky_top_H_NAME'><span lang="ne">सञ्चालक समिति</span> | <span>BOD MEMBERS</span></h2>


            <div className="bod_mem_class_container">
                <BOD
                    src="./assets/BOD_IMGS/03.png"
                    alt="chairman Image"
                    name="Madhan Pradhan"
                    title="Chairman"
                    phone="9855057935" />


            </div>
            <br />
            <div className="bod_mem_class_container">
                <BOD
                    src="./assets/BOD_IMGS/04.png"
                    alt="vice-chairman Image"
                    name="Dipak Bastakoti"
                    title="Vice-Chairman"
                    phone="9855055850" />
                <BOD
                    src="./assets/BOD_IMGS/05.png"
                    alt="Seceratary Image"
                    name="Kamalnath Adhikari"
                    title="Seceratary"
                    phone="9855060570" />
                <BOD
                    src="./assets/BOD_IMGS/06.png"
                    alt="Treasurer Image"
                    name="Mathura Shrestha"
                    title="Treasurer"
                    phone="9845046865" />
            </div>
            <br />
            <div className="bod_mem_class_container">
                <BOD
                    src="./assets/BOD_IMGS/07.png"
                    alt="BOD Member Image - Aasha Shrestha"
                    name="Aasha Shrestha"
                    title="BOD Member"
                    phone="9845052086" />

                <BOD
                    src="./assets/BOD_IMGS/08.png"
                    alt="BOD Member Image - Gobinda Jammerkattel"
                    name="Gobinda Jammerkattel"
                    title="BOD Member"
                    phone="9855050457" />

                <BOD
                    src="./assets/BOD_IMGS/09.png"
                    alt="BOD Member Image - Gopal Bhattrai"
                    name="Gopal Bhattrai"
                    title="BOD Member"
                    phone="9855056382" />

                <BOD
                    src="./assets/BOD_IMGS/10.png"
                    alt="BOD Member Image - Rajan Piya"
                    name="Rajan Piya"
                    title="BOD Member"
                    phone="9855055449" />

                <BOD
                    src="./assets/BOD_IMGS/11.png"
                    alt="BOD Member Image - Pankaj Agrawal"
                    name="Pankaj Agrawal"
                    title="BOD Member"
                    phone="9855056841" />

            </div>

        </div>
        <br />
        <hr className='horizontal_line' />
        <br />

        <div className="bod_member_container" lang="en">
            <h2 className='sticky_top_H_NAME'><span lang="ne">लेखा सुपरिवेक्षण समिति</span> | <span>Account Supervision Comittee</span></h2>


            <div className="bod_mem_class_container">
                <BOD
                    src="./assets/BOD_IMGS/12.jpg"
                    alt="Coordinator Image"
                    name="Bodhanath Subedi"
                    title="Coordinator"
                    phone="9845054057" />


            </div>
            <br />
            <div className="bod_mem_class_container">
                <BOD
                    src="./assets/BOD_IMGS/13.jpg"
                    alt="Member Image"
                    name="Dinesh Piya"
                    title="Member"
                    phone="9845046168" />
                <BOD
                    src="./assets/BOD_IMGS/14.png"
                    alt="Member Image"
                    name="Shanti Piya"
                    title="Member"
                    phone="9845052633" />

            </div>

        </div>
    </>)
}