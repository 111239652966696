import { Link, Route, Routes } from "react-router-dom";
import React, { Component } from 'react';



import './Footer.css';

export function Footer(props) {
    return (
        <>
            <footer>
                <div className="footerContainer">


                    <div className="footer_links_Sum">
                        <h3>Pages</h3>
                        <Link to="/">
                            <li>Home</li>
                        </Link>
                        <Link to="./about/">
                            <li>About Us</li>
                        </Link>
                        <Link to="./contact/">
                            <li>Contact</li>
                        </Link>
                        <Link to="./services/">
                            <li>Services</li>
                        </Link>
                        <Link to="./notices/">
                            <li>Notice</li>
                        </Link>
                        <Link to="https://download.rastriyasaccos.coop.np/">
                            <li>Downloads</li>
                        </Link>

                        <Link to="./BankDetails/">
                            <li>Bank Details</li>
                        </Link>
                    </div>

                    <div className="footer_impLinks">
                        <h3>Important Links</h3>
                        <Link to="https://deoc.gov.np/">DEOC</Link>
                        <Link to="https://mocat.bagamati.gov.np/en/">Ministry Cooperatives(Bagmati Pradesh)</Link>
                        <Link to="https://www.mof.gov.np">MOF</Link>
                        <Link to="https://www.nrb.org.np/">NRB</Link>

                        <Link to="https://elibrary.ncbl.coop">Ncbl Elibrary</Link>
                        <Link to="https://ncbl.coop">Ncbl Website</Link>

                    </div>


                    <div className="office_hours">
                        <h3>Office Hours</h3>
                        <br />
                        Sunday : 10AM - 3PM <br /><br />
                        Tuesday-Thursday : 10AM - 3PM <br /><br />
                        Friday, Saturday : 10AM - 12PM<br /><br />
                        <b>
                            Monday: Holiday<br /><br />
                        </b>
                    </div>
                    <div className="footer_contact_detail">
                        <h3>Contact</h3><br />
                        <Link to="tel:056-598163" style={{ "color": 'white' }}>056-598163</Link>,
                        <Link to="tel:056-598164" style={{ "color": 'white' }}>598164</Link>

                        <br /><br />

                        <Link to="mailto:rastriyasaccos@gmail.com" style={{ "color": 'white' }}>
                            rastriyasaccos@gmail.com</Link>

                    </div>
                </div>
            </footer>
        </>
    );
}

