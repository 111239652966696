import './Banner.css';
import { Link, Route, Routes } from "react-router-dom";
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export function Banner(props) {
    return (
        <>
            <div className='top_banner_container bannerContainer'>
                <Link to="/">
                    <img src='./assets/banner_with_logo.png' className='banner_img' alt="Banner With Logo." />
                </Link>


                <div className="banner_contactInfo" >

                    <ul>

                        <li className='phone'>
                            <FontAwesomeIcon icon={faPhone} />
                            <Link to="tel:056-598163" style={{ 'padding-left': '0.5rem' }}>
                                056-598163</Link>,
                            <Link to="tel:056-598164">598164</Link>
                        </li>
                        <li className='mail'>
                            <FontAwesomeIcon icon={faEnvelope} />

                            <Link to="mailto:rastriyasaccos@gmail.com" style={{ 'padding-left': '0.5rem' }}>
                                rastriyasaccos@gmail.com</Link></li>

                    </ul>
                    <br />

                </div>
            </div>
        </>
    )
}