import React, { Component } from 'react';



import { Card } from "../components/Card"

export function Home() {


    return (<>

        <div className="Main_Content">
            <div style={{ "font-size": "1.5rem" }} lang="ne">

                स्थानीय प्रतिष्ठित, व्यवसायिक, समाजसेवी व्यक्तिहरूको समूहद्वारा सहकारी ऐन २०४८ अन्तर्गत वि.सं. २०५५/६/२१ मा
                स्थापना भै चितवन जिल्लाभर कार्यक्षेत्र रहेको यस राष्ट्रिय बचत तथा ऋण सहकारी संस्था लि. ले सदस्य केन्द्रित भई
                आफ्ना सदस्यहरूको आर्थिक, सामाजिक तथा सांस्कृतिक समृद्धी हासिल गर्नु प्रमुख उद्देश्य रहेको छ । स्वावलम्बन,
                स्व-उत्तरदायित्व, सामाजिक उत्तरदायित्व यस संस्थाको मूल्य एवं मान्यता रहेको छ ।
                <br />
                <br />
                हामी विभिन्न प्रकारका वित्तीय सेवाहरू (बचत, ऋण र रेमिट्यान्स) प्रदान गर्दछौं । सदस्य तथा समुदायको हितलाई
                प्राथमिकतामा राखी समाजको विकासमा सकारात्मक प्रभाव पार्ने अत्याधुनिक सेवाहरुको अध्ययन गरी समयसापेक्ष सुविधा
                प्रदान गर्दै आएको छौँ ।

            </div>
            <br />

            <div lang="en">
                <br /><br /><br />
                <h2 className='txt_align_center'>Our Services</h2>
                <hr /><br />


                <div className="services_items_container">
                    <Card
                        title="Deposit"
                        location="Save for Future."
                        description="Deposits allow individuals to set aside money for future expenses or emergencies. This builds a cushion of savings that can help them weather unexpected financial challenges and avoid debt"

                    />
                    <Card
                        title="Loan & Credits."
                        location=""
                        description=" For entrepreneurs and small business owners, emergencies can arise that require immediate funding, such as equipment breakdowns, unexpected expenses, or loss of inventory. Loans can provide quick access to capital to help keep the business running."

                    />
                </div>
            </div>
        </div>

    </>)
}
