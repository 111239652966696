import { Link, Route, Routes } from "react-router-dom";
import { NavBar } from "./components/NavBar"
import React, { Component } from 'react';


import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Notices } from "./pages/Notices";
import { Services } from "./pages/Services";
import { Downloads } from "./pages/Downloads";
import { BankDetails } from "./pages/BankDetails";

import { NotFound } from "./pages/NotFound";
import { Footer } from "./components/Footer";
import { Banner } from "./components/Banner";

function App() {
  return (
    <>
      <NavBar />

      <div className="container">
        <Banner />
        <div className="routes_container">

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/notices" element={<Notices />} />
            <Route path="/services" element={<Services />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/BankDetails" element={<BankDetails />} />
            
            <Route path="*" element={<NotFound />} />
          </Routes>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default App;
