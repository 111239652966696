import { Link, Route, Routes } from "react-router-dom";
import React, { Component } from 'react';

import "./NavBar.css"
import Background from '../logo.png'
// import { Home } from "../pages/Home";
// import { About } from "../pages/About";
// import { Contact } from "../pages/Contact";
// import { Notices } from "../pages/Notices";
// import { Services } from "../pages/Services";
// import { Downloads } from "../pages/Downloads";
// import { NotFound } from "../pages/NotFound";


function closeMenu(argument) {
    document.getElementById('menuContainer_main').classList.remove("show_mobile");
    document.getElementById('pageSumarry').innerHTML = argument;
};

function openClose() {
    document.getElementById('menuContainer_main').classList.toggle("show_mobile");
}

export function NavBar() {
    return (



        <header>
            <div className="menuContaner">
                <Link to="/">
                    <li>Home</li>
                </Link>
                <Link to="/about/">
                    <li>About Us</li>
                </Link>
                <Link to="/contact/">
                    <li>Contact</li>
                </Link>
                <Link to="/services/">
                    <li>Services</li>
                </Link>
                <Link to="/notices/">
                    <li>Notice</li>
                </Link>
            </div>

        </header>

    )
}