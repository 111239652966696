import React, { Component } from 'react';
import NOTICE from "./NOTICE.json";

import { Notice } from "../components/Notice"
export function Notices() {


    let item = NOTICE[0];
    let a = <Notice
        description={item.description}
        imageURL={item.imageURL}
        image_alt={item.image_alt}
        sumarry={item.sumarry}
        date={item.date}
    />

    item = NOTICE[1];
    let b = <Notice
        description={item.description}
        imageURL={item.imageURL}
        image_alt={item.image_alt}
        sumarry={item.sumarry}
        date={item.date}
    />

    item = NOTICE[2];
    let c = <Notice
        description={item.description}
        imageURL={item.imageURL}
        image_alt={item.image_alt}
        sumarry={item.sumarry}
        date={item.date}
    />

    item = NOTICE[3];
    let d = <Notice
        description={item.description}
        imageURL={item.imageURL}
        image_alt={item.image_alt}
        sumarry={item.sumarry}
        date={item.date}
    />

    item = NOTICE[4];
    let e = <Notice
        description={item.description}
        imageURL={item.imageURL}
        image_alt={item.image_alt}
        sumarry={item.sumarry}
        date={item.date}
    />


    return (<>

        <div className="notice_container" lang="ne">
            {a} {b} {c} {d} {e}
        </div>

    </>)
}
