import React, { Component } from 'react';
import { Link } from "react-router-dom"

import "./Notice.css"



export function Notice(props) {
    if (props.description == "") {
        return;
    }
    return (

        <>
           <div className="notice_ITEM">
                <img className="notice_img" src={props.imageURL} alt={props.image_alt}></img>
                <Link target="_blank" to={"https://rastriyasaccos.coop.np" + props.imageURL}>
                    <div className="notice_text_container">
                        <div className="notice_sumarry">{props.sumarry}</div>
                        <div className="notice_description">{props.description}</div>
                        <div className="notice_date">{props.date}</div>
                    </div>
                </Link>
            </div>


        </>
    )
}