import React, { Component } from 'react';
import { BankDetailBANK } from '../components/BankDetailBANK';
import { Link } from 'react-router-dom';
export function BankDetails() {
    return (
        <>
            <h1 className='underline'>Rastriya Saccos Bank Details.</h1>
            
            <p className='alert_bank'> Please Send voucher or screenshot after deposit at
                <Link to="mailto:rastriyasaccos@gmail.com"> rastriyasaccos@gmail.com</Link>
            </p>
            
            <BankDetailBANK
                BankName="GLOBAL IME BANK LTD."
                AccountNumber="32901010000301"
                AccountName="Rastriya Bachat Tatha Rin Sahakari Sanstha Limited"
                Branch="Pragati Path"
            />
            
            <BankDetailBANK
                BankName="Sunrise BANK LTD."
                AccountNumber="0170481482701001"
                AccountName="Rastriya Saving & Credit Co-Operative Ltd."
                Branch="Narayangath"
            />

            <BankDetailBANK
                BankName="E-Sewa"
                AccountNumber="9801944252"
                AccountName="Rastriya Saving & Credit Co-Operative Ltd."
                Branch=" -"
            />
        </>
    )
}





